(function() {

	var
		className = 'homepage',
		fixed = false,
		topOffset,
		$nav = $(bem.selector(className, 'nav')),
		$header = $(bem.selector(className)),

		onScroll = function() {
			var
				scrollY = core.scroll.y || Math.abs(document.body.getBoundingClientRect().top),
				nowFixed = scrollY >= topOffset;

			if (core.resize.width >= 900) {
				return;
			}

			if (nowFixed === fixed) {
				return;
			}

			fixed = nowFixed;
			$nav.toggleClass('is-fixed', fixed);
		},

		onResize = function() {
			topOffset = $header.get(0).getBoundingClientRect().height
				- $nav.get(0).getBoundingClientRect().height;

			onScroll();

			var
				landscape = ($header.width() / $header.height()) > (1080 / 720);

			$header.toggleClass('site-header--portrait', !landscape);
			$header.toggleClass('site-header--landscape', landscape);
		},

		onChange = function() {
			if (fixed || !$(this).prop('checked')) {
				return;
			}

			$(document).trigger(bem.event('section-nav', 'scroll'), '#data-triggered');
		};

	if (!$header.length) {
		return;
	}

	core.resize[className] = onResize;
	core.scroll.listener[className] = onScroll;

	$(document).on('change', bem.selector(className, 'menu'), onChange);

	onResize();

}());
