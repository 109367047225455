(function() {

	$('.placeholder--video').each(function() {

		var
			$link = $(this),
			$img = $link.find('.placeholder__img').first();

		if (!$img.is('img')) {

			$link.addClass(bem.states.active);
			return;

		}

		$link.on('click', function(e) {

			e.preventDefault();

			if ($link.hasClass(bem.states.active)) {
				return;
			}

			$link.addClass(bem.states.active);

			$img
				.before($('<video />').attr({
					autoplay: true,
					autostart: true,
					controls: true,
					class: 'placeholder__img',
					src: $link.attr('href')
				}))
				.remove();

			$link.removeAttr('href');
		});

	});

}());
