(function() {

	var
		className = 'mobile-nav',
		$toggle = $(bem.selector(className, 'toggle')),

		onClick = function(e) {
			var $target = $(e.target);

			if (!$toggle.prop('checked')) {
				return;
			}

			$toggle.prop('checked', $target.parents(bem.selector(className)).length);
		};

	$(document).on('click touchstart', onClick);

}());
