(function() {

	var
		className = 'our-content',
		$items = $(bem.selector(className, 'tracks') + ' a'),
		targets = [],

		onClick = function(e) {
			var href = (e ? $(e.target) : $items.first()).attr('href');

			targets.forEach(function(target) {
				var isActive = href === target.href;

				target.$link.toggleClass(bem.states.active, isActive);
				target.$target.toggleClass(bem.states.active, isActive);
			});
		};

	if (!$items.length) {
		return;
	}

	$items.each(function() {
		var
			$link = $(this),
			href = $link.attr('href'),
			$target = $(href);

		$target.removeAttr('id');

		targets.push({
			href: href,
			$link: $link,
			$target: $target
		});
	});

	onClick();
	$(document).on('click', bem.selector(className, 'tracks') + ' a', onClick);

}());
