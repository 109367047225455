/* global CountUp */

(function() {

	$('.animate,.animate--no-fade,.animate--slide-left,.animate--slide-right').waypoint({
		offset: '75%',
		handler: function() {
			$(this.element)
				.addClass(bem.states.active)
				.find(bem.selector('count')).each(function() {
					var
						$this = $(this),
						suffix = $this.data('suffix') || null,
						startVal = $this.data('start-val') || 0,
						endVal = $this.text().replace(',', '').replace(suffix || null, '');

					if ($this.data('parsed')) {
						return;
					}

					new CountUp($this.get(0), startVal, endVal, 0, 4, { suffix: suffix }).start();

					$this.data('parsed', true);
				});
		}
	});

}());
