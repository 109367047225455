/* eslint-disable */
$.extend($.easing, {
	easeInOutQuad: function (x, t, b, c, d) {
		if ((t/=d/2) < 1) return c/2*t*t + b;
		return -c/2 * ((--t)*(t-2) - 1) + b;
	},
});
/* eslint-enable */

(function() {

	var
		activeHref,
		className = 'section-nav',
		forcedHref,
		sections = [],
		$primary = $(bem.selector(className)),
		$secondary = $primary.find('.current .secondary-nav'),
		$sections = $(bem.selector(className, 'section')),

		scrollToSection = function(href) {
			var section = null;

			$.each(sections, function(i) {
				if (sections[i].href === href) {
					section = sections[i];
					return false;
				}

				return true;
			});

			forcedHref = section && section.href;

			if (!forcedHref) {
				return;
			}

			$.each(sections, function(i) {
				sections[i].$link.toggleClass('current', sections[i].href === href);
			});

			$('body, html').animate({
				scrollTop: section.top
			}, 500, 'easeInOutQuad');
		},

		onClick = function(e) {
			scrollToSection($(e.currentTarget).attr('href'));
		},

		onScroll = function() {
			var newActiveHref = forcedHref;

			forcedHref = false;

			if (!newActiveHref) {
				$.each(sections, function(i) {
					if (core.scroll.y < sections[i].top) {
						return false;
					}

					newActiveHref = sections[i].href;
					return true;
				});
			}

			if (newActiveHref === activeHref) {
				return;
			}

			$.each(sections, function(i) {
				sections[i].$link.toggleClass('current', sections[i].href === newActiveHref);
			});

			activeHref = newActiveHref;
		},

		onResize = function() {
			var topOffset = $primary.get(0).getBoundingClientRect().height;

			if ($secondary.length) {
				topOffset += $secondary.get(0).getBoundingClientRect().height;
			}

			$.each(sections, function(i) {
				sections[i].top = Math.ceil(
					(sections[i].element.getBoundingClientRect().top
						- document.body.getBoundingClientRect().top)
					- topOffset
				);
			});

			onScroll();
		};

	if (!$sections.length) {
		return;
	}

	$sections.each(function() {
		var
			$section = $(this),
			href = '#' + $section.attr('id'),
			$link = $('a[href="' + href + '"]');

		if (!$link.length) {
			// console.warn('Section not linked to: ' + href);
			return;
		}

		// avoid native scroll on hashchange
		$section.removeAttr('id');

		sections.push({
			$link: $link,
			element: $section.get(0),
			href: href
		});
	});

	$(document).ready(function() {

		core.resize[className] = onResize;
		core.scroll.listener[className] = $.debounce(50, onScroll);

		$(document)
			.on('click', 'a', onClick)
			.on(bem.event(className, 'scroll'), function(e, href) {
				scrollToSection(href);
			});

		onResize();
		scrollToSection(window.location.hash);

	});

}());
